@import "@/assets/styles/global/lib.scss";

.user-dropdown {
  position: absolute;
  top: size(50);
  right: size(-11);
  padding: size(17) size(12) size(12);
  width: size(85);
  background: var(--master-text);
  cursor: alias;
  box-shadow: 0 size(1) size(5) rgba(0, 0, 0, 0.15);
  transition: max-height 0.55s ease-in;
  animation: fadein 0.2s ease-in;
  border-radius: size(5);
  z-index: 1;
  &__wrapper {
    position: relative;
  }
  .arrow-up {
    position: absolute;
    top: size(-23);
    right: size(0);
    width: 0;
    height: 0;
    border-left: size(6) solid transparent;
    border-right: size(6) solid transparent;
    border-bottom: size(6) solid var(--master-text);
  }
  &__name {
    margin-bottom: size(10);
    text-align: center;
    color: var(--ca-title-color);
    font-size: size(16);
    line-height: size(19);
    border-bottom: size(1) solid #E4E8ED;
    padding-bottom: size(10);
  }
  &__link {
    display: block;
    font-size: size(15);
    line-height: size(18);
    font-weight: bold;
    color: var(--onboard-link-color);
    text-align: center;
    margin-bottom: size(2);
    &--text {
      outline: none;
      cursor: pointer;
    }
    &:hover {
      animation: jump-up-shadow 350ms ease-out;
    }
  }
}
