@import "@/assets/styles/global/lib.scss";
.sign-up {
  --promo-mesg-title-font-size: #{size(30)};
  --promo-mesg-title-line-height: #{size(37)};
  --promo-mesg-desc-font-size: #{size(24)};
  --promo-mesg-desc-line-height: #{size(34)};
  --author-desg-font-size: #{size(16)};
  --author-desg-line-height: #{size(19)};
  --author-name-font-size: #{size(24)};
  --author-name-line-height: #{size(29)};
  --sign-up-border-color: #E4E8ED;
  --sign-up-main-title-font-size: #{size(24)};
  --sign-up-main-title-line-height: #{size(29)};
  --sign-up-name-font-size: #{size(16)};
  --sign-up-name-line-height: #{size(19)};
  --sign-up-label-sm-font-size: #{size(14)};
  --sign-up-label-sm-line-height: #{size(17)};
  --sign-up-box-padding-top: auto;

  @media screen and (min-width: $breakpoint-lg) {
    --sign-up-box-padding-top: #{size(50)};
    background-color: var(--onboard-link-color);
    background-image: url('../../signup-bg.png');
    background-repeat: no-repeat;
    background-position: bottom left;
  }

  &.login {
    .hero-banner {
      p {
        display: block;
      }
    }
    .form-wrapper {
      @media screen and (min-width: $breakpoint-lg) {
        min-height: size(504);
      }
    }
  }
  &.forgot-password {
    background: transparent;
    .flex-col-right {
      max-width: size(450);
      min-width: size(450);
      padding: size(30) size(50);
      margin-bottom: size(100);
      border-radius: size(6);
      min-height: size(350);
      h1 {
        font-size: size(27);
        line-height: size(36);
      }
    }
  }
  .hero-banner {
    text-align: left;
    min-height: size(500);
    .logo {
      display: inline-block;
      width: size(108);
      height: size(50);
    }
    &--title {
      font-size: size(32);
      line-height: size(39);
      color: var(--regular-text-inv);
      margin-bottom: size(24);
      margin-top: size(50);
      font-weight: bold;
    }
    svg{
      display: inline-block;
      width: size(16);
      height: size(16);
      margin-top: size(4);
    }
    .content-wrapper {
      display: inline-block;
      vertical-align: top;
      margin-left: size(18);
      width: 90%;
    }
    h2{
      font-size: size(20);
      line-height: size(24);
      font-weight: 600;
      color: var(--regular-text-inv);
      display: inline-block;
      padding-bottom: size(8);
      padding-right: 0;
    }
    p {
      font-size: size(16);
      line-height: size(19);
      color: var(--regular-text-inv);
      display: inline-block;
      padding-bottom: size(24);
    }
  }

  .signup-text {
    font-size: var(--sign-up-main-title-font-size);
    line-height: var(--sign-up-main-title-line-height);
    // font-family: var(--hero-font);
    font-weight: bold;
    color: var(--ca-title-color);
    margin-bottom: size(10);
  }
  .login-info {
    font-size: var(--sign-up-name-font-size);
    line-height: var(--sign-up-name-line-height);
    padding-bottom: size(30);
    &-link {
      color: var(--onboard-link-color);
      font-weight: 600;
    }
  }
  .form-group {
    transition: all 0.3s ease-in;
    &:empty {
      margin: 0;
    }
    margin-bottom: size(30);
    &.password-div{
      margin-bottom: size(40);
    }
    p {
      margin-top: size(5);
    }
    .error-msg.hide {
      transform: scale(0);
    }
    &.error-field {
      input {
        outline: none;
        box-shadow: none;
        border-color: var(--onboard-error-color);
      }
      textarea {
        outline: none;
        box-shadow: none;
        border: size(2) solid var(--onboard-error-color);
      }
      .error-msg {
        transform: scale(1);
        color: var(--onboard-error-color);
      }
    }
    input {
      padding: size(10);
      width: 100%;
      box-sizing: border-box;
      font-size: size(18);
      line-height: size(22);
      border: 0;
      border: size(2) solid var(--sign-up-border-color);
      color: var(--ca-title-color);
      font-family: var(--regular-font);
      border-radius: size(3);
      background-color: var(--on-boarding-primary-backgroundColor);
      &:focus {
        outline: none;
        border: size(2) solid var(--onboard-link-color);
      }
    }
    .field-label {
      display: block;
      font-size: size(14);
      line-height: size(17);
      color: var(--ca-title-color);
      font-family: var(--regular-font);
      padding-bottom: size(6);
      font-weight: 600;
      &.password {
        display: inline-block;
      }
    }
    .forget-pass-btn {
      float: right;
      font-size: size(14);
      line-height: size(17);
      color: var(--onboard-link-color);;
      font-family: var(--regular-font);
      padding-bottom: size(6);
      font-weight: 600;
      &:focus {
        text-decoration: underline;
        outline: none;
      }
    }
  }
  
  margin: auto;
  .flex {
    display: flex;
    justify-content: space-evenly;
    max-width: 100%;
    // margin-top: size(-70);
    // margin-bottom: size(10);
    flex-direction: column-reverse;
    @media screen and (min-width: $breakpoint-lg) {
      flex-direction: row;
    }
    &-col {
      &-left {
        color: var(--ca-title-color);
        width: size(675);
        display: none;
        align-self: flex-start;
        padding: var(--sign-up-box-padding-top) 0 0 size(80);

        @media screen and (min-width: $breakpoint-lg) {
          display: block;
        }
        .promo-mesg-title {
          font-size: var(--promo-mesg-title-font-size);
          line-height: var(--promo-mesg-title-line-height);
          padding-right: size(0);
          text-align: center;
          width: 100%;
          max-width: size(550);
          color: var(--ca-title-color);
          margin: auto;
          @media screen and (min-width: $breakpoint-lg) {
            margin-bottom: size(40);
          }
        }
      }
      &-right {
        box-sizing: border-box;
        // min-width: size(500);
        padding: size(50) size(40) size(100);
        // border-radius: size(6);
        align-self: center;
        min-height: 100vh;
        // margin-top: size(70);
        // margin-bottom: size(50);
        background: var(--master-text);
        position: relative;
        width: 100%;
        max-width: 100%;
        @media screen and (min-width: $breakpoint-md) {
          padding: size(50) size(60) size(150);
        }
        @media screen and (min-width: $breakpoint-lg) {
          align-self: flex-start;
          margin-top: unset;
          margin-bottom: unset;
          min-width: size(750);
          margin-left: size(75);
        }
        .form-wrapper {
          @media screen and (min-width: $breakpoint-lg) {
            max-width: size(380);
            padding: size(100) size(100) size(100);
            margin: 0 auto;
          }
        }
        .register-form {
          padding-bottom: size(50);
          @media screen and (min-width: $breakpoint-md) {
            padding-bottom: 0;
          }
          &>.error-msg {
            color: var(--onboard-error-color);
            padding-bottom: size(10);
          }
        }
        a { 
          &.console-btn {
            padding: size(13) 0;
          }
        }
        .footer {
          font-size: size(14);
          line-height: size(17);
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          display: block;
          padding: 0;
          margin: auto;
          @media screen and (min-width: $breakpoint-md) {
            display: flex;
            justify-content: space-between;
            padding: size(11) var(--onboard-padding) size(11) var(--onboard-padding);
          }
          & .footer-flex-col {
            &-left {
              padding-left: 0;
            }
          }
          & .footer-flex-col {
            &-right {
              margin-top: size(15);
              @media screen and (min-width: $breakpoint-md) {
                margin-top: 0;
              }
              .link-item {
                margin-right: size(16);
                margin-bottom: size(8);
                @media screen and (min-width: $breakpoint-md) {
                  margin-left: size(16);
                  margin-bottom: 0;
                  margin-right: 0;
                }
                color: var(--regular-text);
                display: inline-block;
              }
            }
          }
        }
        
        .console-btn {
          // margin-bottom: size(36);
          outline: none;
          font-weight: bold;
          &:hover {
              color: var(--regular-text-inv);
          }
        }
        .success-field {
          text-align: center;
          &.email {
            p {
              padding-top: size(20);
              padding-bottom: 0;
            }
          }
          h1 {
            padding: size(10) 0;
            font-weight: bold;
            font-size: size(42);
            line-height: size(56);
            color: var(--ca-title-color);
            font-family: var(--hero-font);
          }
          p {
            padding-bottom: size(30);
            font-weight: 600;
            font-size: size(16);
            line-height: size(19);
            color: var(--ca-title-color);
          }
        }

        .text-or {
          font-size: var(--sign-up-name-font-size);
          line-height: var(--sign-up-name-line-height);
          margin-bottom: size(16);
          text-align: center;
        }
        .checkbox-content {
          display: inline-block;
          vertical-align: middle;
          margin-top: size(20);
          padding-left: size(10);
          font-size: size(16);
          line-height: size(19);
          cursor: pointer;
          color: var(--ca-title-color);
        }
        .check-box {
          display: inline-block;
          vertical-align: top;
          width: size(20);
          height: size(20);
          margin-top: size(20);
        }
        .signup-btn {
          font-size: var(--sign-up-name-font-size);
          line-height: var(--sign-up-name-line-height);
          border-radius: size(6);
          border: size(1) solid var(--sign-up-border-color);
          padding: size(10) size(16);
          cursor: pointer;
          outline: none;
          vertical-align: middle;
          &:hover {
            animation: jump-up-shadow 350ms ease-out;
          }
        }
        .social-signup-group {
          text-align: center;
          img {
            vertical-align: middle;
            padding-right: size(8);
          }
          .signup-btn:first-of-type {
            margin-right: size(26);
          }
        }
        .warning {
          padding: size(15) 0;
          &-info {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-in;
            p {
              display: inline-block;
              padding-left: size(5);
              color: var(--onboard-error-color);
            }
          }
          &:last-child {
            padding-bottom: size(21);
          }
          &.check-box {
            padding-bottom: size(40);
          }
        }
        .check-box {
          &-label {
            font-size: var(--author-desg-font-size);
            line-height: var(--author-desg-line-height);
            color: var(--regular-text);
            vertical-align: middle;
          }
          &-link {
            font-size: var(--author-desg-font-size);
            line-height: var(--author-desg-line-height);
            color: var(--onboard-link-color);
            font-weight: normal;
            outline: none;
          }
          &-group {
            display: inline-block;
            vertical-align: top;
            position: relative;
            font-size: var(--author-desg-font-size);
            line-height: var(--author-desg-line-height);
            color: var(--regular-text);
            outline: none;
            cursor: pointer;
            input[type='checkbox'] {
              visibility: hidden;
            }
            &.terms-and-conditions {
              font-weight: normal;
              margin-bottom: size(20);
              font-size: var(--sign-up-label-sm-font-size);
              color: var(--regular-text);
              span {
                font-weight: normal;
              }
              a {
                text-decoration: underline;
              }
            }
          }
        }

        .tickmark {
          position: absolute;
          top: size(18);
          left: 0;
          border-radius: size(4);
          border: size(1) solid var(--onboard-link-color);
          width: size(20);
          height: size(20);
          cursor: pointer;
        }

        .check-box-group input:checked ~ .tickmark {
          background-color: transparent;
        }

        .tickmark:after {
          content: '';
          position: absolute;
          display: none;
        }

        .check-box-group input:checked ~ .tickmark:after {
          display: block;
        }

        .check-box-group .tickmark:after {
          left: size(6);
          border: size(2) solid var(--onboard-link-color);
          border-top: solid var(--regular-text-inv);
          border-left: solid var(--regular-text-inv);
          bottom: size(7);
          width: size(2);
          height: size(7);
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        &.initiated {
          .tick-icon {
            background-image: url('../../../assets/tick-icon.svg');
            background-repeat: no-repeat;
            background-position: 98% 50%;
            background-size: size(14) size(10);
          }
          .show {
            max-height: size(24);
          }
          .hide {
            max-height: 0;
          }
        }
      }
    }
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    &.warning-icon {
      height: size(12);
      width: size(12);
    }
    &.google-icon,
    &.github-icon {
      height: size(28);
      width: size(28);
      padding-right: size(8);
    }
  }
  .alert-warning {
    padding: size(5) 0;
    p {
      font-size: size(16);
      color: var(--onboard-error-color);
    }
  }
  .image-container, .hide-container {
    max-width: size(335);
  }
  .image-item {
    text-align: left;
  }
}