@import "@/assets/styles/global/lib.scss";

.image-area {
  .show-container {
    img {
      max-height: size(435);
    }
  }
}
.sign-up {
  .flex-col-right {
    .form-wrapper {
      padding-bottom: size(100);
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(200);
      }
    }
  }
}
