@import "@/assets/styles/global/lib.scss";

.forgot-password {
  .flex-col-right {
    margin-left: 0;
    margin-top: size(100);
    max-width: size(500);
    .title {
      margin-bottom: size(30);
    }
    .button {
      margin-top: size(14);
    }
    .create-password-block {
      & >.error-msg {
        color: var(--onboard-error-color);
        padding: 0 0 size(10);
      }
      .checkbox-content {
        display: inline-block;
        vertical-align: middle;
        margin-top: size(20);
        padding-left: size(10);
        font-size: size(16);
        line-height: size(19);
        color: var(--ca-title-color);
      }
    }
    .success-field {
      text-align: center;
      &.email {
        p {
          padding-top: size(20);
          padding-bottom: 0;
        }
      }
      h1 {
        padding: size(10) 0;
        font-weight: bold;
        font-size: size(42);
        line-height: size(56);
        color: var(--ca-title-color);
        font-family: var(--hero-font);
      }
      p {
        padding-bottom: size(30);
        font-weight: 600;
        font-size: size(16);
        line-height: size(19);
        color: var(--ca-title-color);
      }
    }
  }
}
